import {Button, Modal} from "antd";
import React, {useState} from "react";
import {v4 as uuidv4} from "uuid";
import CreateItemForm from "../Forms/CreateItemForm";
import CreateServiceForm from "../Forms/CreateServiceForm";

const CreateServiceModal: React.FC<{ add: (any: any) => void }> = ({add}) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [newService, setNewService] = React.useState({
        title: "",
        description: "",
        id: uuidv4()
    });

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        if (newService) {
            add({
                ...newService,
            });
        }
        setOpen(false);
        setConfirmLoading(false);
        setNewService({
            title: "",
            description: "",
            id: uuidv4()
        })
    }

    const handleCancel = () => {
        setOpen(false);
        setNewService({
            title: "",
            description: "",
            id: uuidv4()
        })
    }

    return (
        <>
            <Button type="primary" className="bg-cyan-800" onClick={showModal}>
                Create
            </Button>
            <Modal
                title="Add new"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okButtonProps={{className: "bg-cyan-800"}}
                destroyOnClose={true}
            >
                <CreateServiceForm setNewBlog={setNewService} newBlog={newService}/>
            </Modal>
        </>
    )
}

export default CreateServiceModal;
