import {CustomTable} from "../components/Tables";
import {useServices} from "../hooks/useServices.hook";
import CreateServiceModal from "../components/Modals/createService.modal";
import DeleteModal from "../components/Modals/delete.modal";

export const Services = () => {
    const {services, loadOne, add, update, remove} = useServices()

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'actions',
            render: (id: number) => (
                <div className="flex gap-x-4">
                    {/*<Button type="primary" className="bg-cyan-800">Edit</Button>*/}
                    <DeleteModal remove={remove} view={"Напрям"} id={id}/>
                </div>
            ),
        }
    ]

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="text-5xl">Напрями</h1>
                <CreateServiceModal add={add}/>
            </div>
            <CustomTable columns={columns} data={services}/>
        </div>
    );
}
