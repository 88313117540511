import axios from "axios";

import Cookies from "js-cookie";
import {IBlog, IBlogAdd} from "../interfaces/blog.interface";

const baseUrl = "https://kovalchuk-clinic-api.onrender.com/";
const token = Cookies.get("token");

axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            Cookies.remove("token");
            window.location.href = "/";
        }
        return Promise.reject(error);
    });

export const getBlogs = async () => {
    const response = await axios.get(baseUrl + "blogs");
    return response.data;
};

export const getBlogById = async (id: number) => {
    const response = await axios.get(baseUrl + `blogs/${id}`);
    return response.data;
}

export const createBlog = async (blog: IBlogAdd) => {
    const response = await axios.post(baseUrl + "blogs", blog);
    return response.data;
}

export const updateBlog = async (id: number, blog: IBlog) => {
    const response = await axios.patch(baseUrl + `blogs/${id}`, blog);
    return response.data;
}

export const deleteBlog = async (id: number) => {
    const response = await axios.delete(baseUrl + `blogs/${id}`);
    return response.data;
}
