
import {useEffect, useState} from "react";
import {IService} from "../interfaces/service.interface";
import {createService, deleteService, getServiceById, getServices, updateService} from "../api/services";

export function useServices() {

    const [services, setServices] = useState<IService[]>([]);

    const refetchServices = async () => {
        const data = await getServices()
        setServices(data)
    }

    useEffect(() => {
        const getData = async () => {
            const data = await getServices()
            setServices(data)
        }
        getData().catch(e => console.log(e))
    }, [])

    const loadOne = async (id: number) => {
        return await getServiceById(id);
    }

    const add = async (service: IService) => {
        await createService(service);
        await refetchServices();
    }

    const update = async (id: number, service: IService) => {
        await updateService(id, service);
        await refetchServices();

    }

    const remove = async (id: number) => {
        await deleteService(id);
        await refetchServices();
    }

    return {services, loadOne, add, update, remove};
}
