import React from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function CustomEditor({setNewBlog, category, newBlog}: {
    setNewBlog: any,
    category: string,
    newBlog: any
}) {

    if (category === "description") {
        return (
            <CKEditor
                editor={ClassicEditor}
                data={newBlog[category].value}
                onChange={(event, editor) => {
                    setNewBlog({...newBlog, [category]: editor.getData()})
                }}
            />
        )
    }

    return (
        newBlog[category].name && <CKEditor
            editor={ClassicEditor}
            data={newBlog[category].value}
            onChange={(event, editor) => {
                setNewBlog({...newBlog, [category]: {...newBlog[category] , value: editor.getData()}})
            }}
        />
    )
}
