import axios from "axios";

import Cookies from "js-cookie";
import {IService} from "../interfaces/service.interface";

const baseUrl = "https://kovalchuk-clinic-api.onrender.com/";
const token = Cookies.get("token");

axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            Cookies.remove("token");
            window.location.href = "/";
        }
        return Promise.reject(error);
    });

export const getServices = async () => {
    const response = await axios.get(baseUrl + "services");
    return response.data;
};

export const getServiceById = async (id: number) => {
    const response = await axios.get(baseUrl + `services/${id}`);
    return response.data;
}

export const createService = async (service: IService)=> {
    const response = await axios.post(baseUrl + "services", service);
    return response.data;
}

export const updateService = async (id: number, service: IService) => {
    const response = await axios.patch(baseUrl + `services/${id}`, service);
    return response.data;
}

export const deleteService= async (id: number) => {
    const response = await axios.delete(baseUrl + `services/${id}`);
    return response.data;
}
