import {Blogs} from "./Blogs";
import Cookies from "js-cookie";
import React, {useEffect} from "react";
import {Login} from "./Login";
import {Items} from "./Items";
import {AppLayout} from "../components/Layout";
import {Services} from "./Services";
import {Doctors} from "./Doctors";

export const Home = ({view, setView}: { view: string, setView: any }) => {

    useEffect(() => {
        if (Cookies.get('token')) {
            return
        } else {
            setView('login')
            return
        }
    }, [setView]);

    const handleView = () => {
        switch (view) {
            case 'blogs':
                return <Blogs/>
            case 'items':
                return <Items/>
            case 'services':
                return <Services/>
            case 'doctors':
                return <Doctors/>
            default:
                return <Blogs/>
        }
    }

    if (view === 'login') {
        return (
            <Login />
        )
    }

    return (
        <AppLayout setView={setView}>
            {handleView()}
        </ AppLayout>
    )
}
