import React, {useState} from 'react';
import {Button, Form, Input, Modal} from 'antd';
import {IBlog, IBlogAdd} from "../../interfaces/blog.interface";
import CustomBlogForm from "../Forms/forms";
import {v4 as uuidv4} from 'uuid';

const CreateModal: React.FC<{ add: (any: any) => void }> = ({add}) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [newBlog, setNewBlog] = useState<IBlog>({
        id: uuidv4(),
        title: "",
        description: "",
        keywords: "",
        image: "",
        date: Math.floor(Date.now() / 1000).toString(),
        category: "Захворювання",
        block1: {name: "", value: ""},
        block2: {name: "", value: ""},
        block3: {name: "", value: ""},
        block4: {name: "", value: ""}
    });
    const showModal = () => {
        setOpen(true);
    };

    const handleOk = async () => {
        setConfirmLoading(true);
        if (newBlog) {
            add({
                ...newBlog,
                block1: JSON.stringify(newBlog.block1),
                block2: JSON.stringify(newBlog.block2),
                block3: JSON.stringify(newBlog.block3),
                block4: JSON.stringify(newBlog.block4)
            });
        }
        setOpen(false);
        setConfirmLoading(false);
        setNewBlog({
            id: uuidv4(),
            title: "",
            description: "",
            keywords: "",
            image: "",
            date: Math.floor(Date.now() / 1000).toString(),
            category: "",
            block1: {name: "", value: ""},
            block2: {name: "", value: ""},
            block3: {name: "", value: ""},
            block4: {name: "", value: ""}
        })
    };

    const handleCancel = () => {
        setNewBlog({
            id: uuidv4(),
            title: "",
            description: "",
            keywords: "",
            image: "",
            date: Math.floor(Date.now() / 1000).toString(),
            category: "",
            block1: {name: "", value: ""},
            block2: {name: "", value: ""},
            block3: {name: "", value: ""},
            block4: {name: "", value: ""}
        })
        setConfirmLoading(false);
        setOpen(false);
    };


    return (
        <>
            <Button type="primary" className="bg-cyan-800" onClick={showModal}>
                Create
            </Button>
            <Modal
                title="Add new"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okButtonProps={{className: "bg-cyan-800"}}
                destroyOnClose={true}
            >
                <CustomBlogForm setNewBlog={setNewBlog} newBlog={newBlog}/>
            </Modal>
        </>
    );
};

export default CreateModal;
