import {Button, Modal} from "antd";
import React, {useState} from "react";
import {v4 as uuidv4} from "uuid";
import CreateItemForm from "../Forms/CreateItemForm";
import CreateServiceForm from "../Forms/CreateServiceForm";
import CreateDoctorForm from "../Forms/CreateDoctorsForm";

const CreateDoctorsModal: React.FC<{ add: (any: any) => void }> = ({add}) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [newDoctor, setNewDoctor] = React.useState({
        name: "",
        image: "",
        id: uuidv4(),
        description: "",
        service: "",
        shortDescription: "",
        type: ""
    });

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        if (newDoctor) {
            add({
                ...newDoctor,
            });
        }
        setOpen(false);
        setConfirmLoading(false);
        setNewDoctor({
            name: "",
            image: "",
            id: uuidv4(),
            description: "",
            service: "",
            shortDescription: "",
            type: ""
        })
    }

    const handleCancel = () => {
        setOpen(false);
        setNewDoctor({
            name: "",
            image: "",
            id: uuidv4(),
            description: "",
            service: "",
            shortDescription: "",
            type: ""
        })
    }

    return (
        <>
            <Button type="primary" className="bg-cyan-800" onClick={showModal}>
                Create
            </Button>
            <Modal
                title="Add new"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okButtonProps={{className: "bg-cyan-800"}}
                destroyOnClose={true}
            >
                <CreateDoctorForm setNewBlog={setNewDoctor} newBlog={newDoctor}/>
            </Modal>
        </>
    )
}

export default CreateDoctorsModal;
