
import {useEffect, useState} from "react";
import {IDoctor} from "../interfaces/doctors.interface";
import {createDoctor, deleteDoctor, getDoctorById, getDoctors, updateDoctor} from "../api/doctors";

export function useDoctors() {

    const [doctors, setDoctors] = useState<IDoctor[]>([]);

    const refetchDoctors = async () => {
        const data = await getDoctors()
        setDoctors(data)
    }

    useEffect(() => {
        const getData = async () => {
            const data = await getDoctors()
            setDoctors(data)
        }
        getData().catch(e => console.log(e))
    }, [])

    const loadOne = async (id: number) => {
        return await getDoctorById(id);
    }

    const add = async (doctor: IDoctor) => {
        await createDoctor(doctor);
        await refetchDoctors();
    }

    const update = async (id: number, doctor: IDoctor) => {
        await updateDoctor(id, doctor);
        await refetchDoctors();

    }

    const remove = async (id: number) => {
        await deleteDoctor(id);
        await refetchDoctors();
    }

    return {doctors, loadOne, add, update, remove};
}
