import {useItems} from "../hooks/useItems.hook";
import {CustomTable} from "../components/Tables";
import Base64ToImg from "../utils/Base64ToImg";
import {unixToDate} from "../utils/unixToDate";
import DeleteModal from "../components/Modals/delete.modal";
import CreateModal from "../components/Modals/create.modal";
import CreateItemModal from "../components/Modals/createItem.modal";

export const Items = () => {
    const {items, loadOne, add, update, remove} = useItems()
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image: string) => (
                <Base64ToImg base64={image}/>
            ),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Service',
            dataIndex: 'service',
            key: 'service',
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'actions',
            render: (id: number) => (
                <div className="flex gap-x-4">
                    {/*<Button type="primary" className="bg-cyan-800">Edit</Button>*/}
                    <DeleteModal remove={remove} view="Item" id={id}/>
                </div>
            ),
        }
    ];

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="text-5xl">Послуги</h1>
                <CreateItemModal add={add} />
            </div>
            <CustomTable columns={columns} data={items}/>
        </div>
    )

}
