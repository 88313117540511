import * as React from "react"
const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={60} height={55} fill="none">
    <rect
      width={26.385}
      height={47.105}
      fill="#7E22CE"
      rx={13.193}
      transform="matrix(-.81582 -.5783 -.56885 .82244 60 16.075)"
    />
    <rect
      width={26.385}
      height={47.105}
      fill="#F998CB"
      rx={13.193}
      transform="matrix(.81582 -.5783 .56885 .82244 0 16.075)"
    />
    <path
      fill="#F472B6"
      fillRule="evenodd"
      d="M41.938 43.374c2.61-4.46 2.566-10.219-.583-14.728L30.178 12.639 19 28.645c-3.15 4.51-3.193 10.27-.582 14.73l.644.923a13.563 13.563 0 0 0 11.089 5.81 13.564 13.564 0 0 0 11.142-5.81l.645-.924Z"
      clipRule="evenodd"
    />
  </svg>
)
export default Logo
