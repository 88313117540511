import React from 'react';


export const Base64ToImg: React.FC<{ base64: string }> = ({base64}) => {
    return (
            <img src={base64} alt="1" className="w-[200px] h-[150px]"/>
    )
}

export default Base64ToImg;
