
import {useEffect, useState} from "react";
import {getBlogs, createBlog, deleteBlog, updateBlog, getBlogById} from "../api/blogs";
import {IBlog, IBlogAdd} from "../interfaces/blog.interface";

export function useBlogs() {

    const [blogs, setBlogs] = useState<IBlog[]>([]);

    const refetchBlogs = async () => {
        const data = await getBlogs()
        setBlogs(data)
    }

    useEffect(() => {
        const getData = async () => {
            const data = await getBlogs()
            setBlogs(data)
        }
        getData().catch(e => console.log(e))
    }, [])

    const loadOne = async (id: number) => {
        return await getBlogById(id);
    }

    const add = async (blog: IBlogAdd) => {
        await createBlog(blog);
        await refetchBlogs();
    }

    const update = async (id: number, blog: IBlog) => {
        await updateBlog(id, blog);
        await refetchBlogs();

    }

    const remove = async (id: number) => {
        await deleteBlog(id);
        await refetchBlogs();
    }

    return {blogs, loadOne, add, update, remove};
}
