import React, { useState } from 'react';
import { Button, Modal } from 'antd';

const DeleteModal: React.FC<{remove: (id: number)=> void, id: number, view: string}> = ({remove, id, view}) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = async () => {
        setConfirmLoading(true);
        await remove(id);
        setOpen(false);
        setConfirmLoading(false);
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    return (
        <>
            <Button type="primary" danger onClick={showModal}>
                Delete
            </Button>
            <Modal
                title="Title"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okButtonProps={{className: "bg-cyan-800"}}
            >
                Delete this {view}?
            </Modal>
        </>
    );
};

export default DeleteModal;
