import React from 'react';
import './App.css';
import {ViewContext} from "./context";
import {Home} from "./pages";

function App() {
    const [view, setView] = React.useState('blogs')
    return (
        <ViewContext.Provider value={{view, setView}}>
            <Home view={view} setView={setView}/>
        </ViewContext.Provider>
    );
}

export default App;
