import { Input} from "antd";
import React, {useEffect} from "react";
import {IBlog} from "../../interfaces/blog.interface";
import CustomEditor from "./Editor";
import ImageToBase64 from "../../utils/ImageToBase64";



const CustomBlogForm: React.FC<{setNewBlog: (blog: any) => void, newBlog: IBlog }> = ({setNewBlog, newBlog}) => {

    const handleChange = (e: any) => {
        setNewBlog({...newBlog, [e.target.name]: e.target.value})
    }

    const onChangeBlockName = (e: any) => {
        // @ts-ignore
        setNewBlog({...newBlog, [e.target.name]: { value: newBlog[e.target.name].value , name: e.target.value}})
    }

    return (
        <div className="flex flex-col gap-y-6">
            <span className="flex flex-col gap-y-2">
                Title
                <input className="border border-black p-2 rounded-l" name="title" id="title" value={newBlog.title}
                       onChange={handleChange}/>
            </span>
            <span className="flex flex-col gap-y-2">
                Description
                <CustomEditor setNewBlog={setNewBlog} category={"description"} newBlog={newBlog}/>
            </span>
            <span className="flex flex-col gap-y-2">
                Category
                <select className="p-2 border border-black rounded-xl" onChange={handleChange} name="category" id="category" value={newBlog.category}>
                    <option value="Захворювання">Захворювання</option>
                    <option value="Діагностика">Діагностика</option>
                    <option value="Краса і здоровʼя">Краса і здоровʼя</option>
                     <option value="Педіатр і я">Педіатр і я</option>
                     <option value="Наші методи лікування">Чому ми лікуємо так, а не інакше</option>
                </select>
                {/*<input className="border border-black p-4 rounded-l" name="category" id="category" value={newBlog.category}*/}
                {/*       onChange={handleChange}/>*/}
            </span>
            <span className="flex flex-col gap-y-2">
                Image
                <ImageToBase64 setNewBlog={setNewBlog} newBlog={newBlog}/>
            </span>
            <span className="flex flex-col gap-y-2">
                Block1
                 <input className="border border-black p-2 rounded-l" id="block1" name="block1" value={newBlog["block1"].name} onChange={onChangeBlockName}/>
                <CustomEditor setNewBlog={setNewBlog} newBlog={newBlog} category={"block1"}/>
            </span>
            <span className="flex flex-col gap-y-2">
                Block2
                <input className="border border-black p-2 rounded-l" id="block2" name="block2" value={newBlog["block2"].name} onChange={onChangeBlockName}/>
                <CustomEditor setNewBlog={setNewBlog} newBlog={newBlog} category={"block2"}/>
            </span>
            <span className="flex flex-col gap-y-2">
                Block3
                <input className="border border-black p-2 rounded-l" id="block3" name="block3" value={newBlog["block3"].name} onChange={onChangeBlockName}/>
                <CustomEditor setNewBlog={setNewBlog} newBlog={newBlog} category={"block3"}/>
            </span>
            <span className="flex flex-col gap-y-2">
                Block4
                <input className="border border-black p-2 rounded-l" id="block4" name="block4" value={newBlog["block4"].name} onChange={onChangeBlockName}/>
                <CustomEditor setNewBlog={setNewBlog} newBlog={newBlog} category={"block4"}/>
            </span>
        </div>
    )
}

export default CustomBlogForm
