import ImageToBase64 from "../../utils/ImageToBase64";
import React from "react";
import {v4 as uuidv4} from "uuid";
import {useServices} from "../../hooks/useServices.hook";

const CreateItemForm = ({setNewBlog, newBlog} : {setNewBlog: any, newBlog: any}) => {

    const {services} = useServices()

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setNewBlog({
            ...newBlog,
            [name]: value
        })
    }

    return (
        <div className="flex flex-col gap-y-3">
            <label className="flex flex-col gap-y-2">
                Title:
                <input className="p-2 border border-black rounded-xl" type="text" name="title" value={newBlog.name} onChange={handleChange}/>
            </label>
            <label className="flex flex-col gap-y-2">
                Price:
                <input className="p-2 border border-black rounded-xl" type="text" name="price" value={newBlog.price} onChange={handleChange}/>
            </label>
            <label className="flex flex-col gap-y-2">
                Service:
                <select className="p-2 border border-black rounded-xl" name="service" value={newBlog.service} onChange={handleChange}>
                    {services.map((service: any) => (
                        <option key={service.id} value={service.id}>{service.title}</option>
                    ))}
                </select>
            </label>
            <label className="flex flex-col gap-y-2">
                <span className="flex flex-col gap-y-2">
                Image
                <ImageToBase64 setNewBlog={setNewBlog} newBlog={newBlog}/>
                </span>
            </label>
        </div>
    )
}

export default CreateItemForm;
