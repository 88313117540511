import { FormEvent } from 'react'
import Cookies from "js-cookie";

export const Login = () => {

    async function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()

        const formData = new FormData(event.currentTarget)
        const login = formData.get('login')
        const password = formData.get('password')

        const response = await fetch('https://kovalchuk-clinic-api.onrender.com/auth/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: login, password }),
        })

        if (response.ok) {
            Cookies.set('token', (await response?.json())?.token, { expires: 7 })
            window.location.href = "/"
        } else {
            alert('Invalid credentials')
        }
    }

    return (
        <div className="h-screen grid place-content-center">
            <form onSubmit={handleSubmit} className="flex flex-col items-center justify-center gap-y-4">
                <input type="text" name="login" placeholder="Email" required className="border border-black rounded-xl p-2"/>
                <input type="password" name="password" placeholder="Password" required className="border border-black rounded-xl p-2"/>
                <button type="submit" className="border border-black rounded-xl p-2">Login</button>
            </form>
        </div>
    )
}
