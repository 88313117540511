import { createContext, useContext } from "react"
export type ViewContextType = {
    view: string
    setView:(c: string) => void
}
export const ViewContext = createContext<ViewContextType>({
    view: "blogs",
    setView: () => {},
})
export const useViewContext = () => useContext(ViewContext)
