
import {useEffect, useState} from "react";
import {createItem, deleteItem, getItemById, getItems, updateItem} from "../api/items";
import {IItem} from "../interfaces/item.interface";

export function useItems() {

    const [items, setItems] = useState<IItem[]>([]);

    const refetchItems = async () => {
        const data = await getItems()
        setItems(data)
    }

    useEffect(() => {
        const getData = async () => {
            const data = await getItems()
            setItems(data)
        }
        getData().catch(e => console.log(e))
    }, [])

    const loadOne = async (id: number) => {
        return await getItemById(id);
    }

    const add = async (item: IItem) => {
        await createItem(item);
        await refetchItems();
    }

    const update = async (id: number, item: IItem) => {
        await updateItem(id, item);
        await refetchItems();

    }

    const remove = async (id: number) => {
        await deleteItem(id);
        await refetchItems();
    }

    return {items, loadOne, add, update, remove};
}
