import {CustomTable} from "../components/Tables";
import Base64ToImg from "../utils/Base64ToImg";
import DeleteModal from "../components/Modals/delete.modal";
import CreateDoctorsModal from "../components/Modals/createDoctors.modal";
import {useDoctors} from "../hooks/useDoctors.hook";

export const Doctors = () => {
    const {doctors, loadOne, add, update, remove} = useDoctors()
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image: string) => (
                <Base64ToImg base64={image}/>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Service',
            dataIndex: 'service',
            key: 'service',
        },
        {
            title: 'Short Description',
            dataIndex: 'shortDescription',
            key: 'shortDescription',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'actions',
            render: (id: number) => (
                <div className="flex gap-x-4">
                    {/*<Button type="primary" className="bg-cyan-800">Edit</Button>*/}
                    <DeleteModal remove={remove} view="Item" id={id}/>
                </div>
            ),
        }
    ];

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="text-5xl">Лікарі</h1>
                <CreateDoctorsModal add={add} />
            </div>
            <CustomTable columns={columns} data={doctors}/>
        </div>
    )

}
