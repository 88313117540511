import React from "react";
import ImageToBase64 from "../../utils/ImageToBase64";
import CustomEditor from "./Editor";
import {useServices} from "../../hooks/useServices.hook";

const CreateDoctorForm = ({setNewBlog, newBlog} : {setNewBlog: any, newBlog: any}) => {
    const {services} = useServices()
    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setNewBlog({
            ...newBlog,
            [name]: value
        })
    }

    return (
        <div className="flex flex-col gap-y-3">
            <label className="flex flex-col gap-y-2">
                Name:
                <input className="p-2 border border-black rounded-xl" type="text" name="name" value={newBlog.name}
                       onChange={handleChange}/>
            </label>
            <label className="flex flex-col gap-y-2">
                Description:
                <CustomEditor setNewBlog={setNewBlog} category={"description"} newBlog={newBlog}/>
            </label>
            <label className="flex flex-col gap-y-2">
                Service:
                <select className="p-2 border border-black rounded-xl" name="service" value={newBlog.service}
                        onChange={handleChange}>
                    {services.map((service: any) => (
                        <option key={service.id} value={service.id}>{service.title}</option>
                    ))}
                </select>
            </label>
            <label className="flex flex-col gap-y-2">
                <span className="flex flex-col gap-y-2">
                Image
                <ImageToBase64 setNewBlog={setNewBlog} newBlog={newBlog}/>
                </span>
            </label>
            <label className="flex flex-col gap-y-2">
                Short description:
                <input className="p-2 border border-black rounded-xl" type="text" name="shortDescription" value={newBlog.shortDescription}
                       onChange={handleChange}/>
            </label>
            <label className="flex flex-col gap-y-2">
                Type:
                <input className="p-2 border border-black rounded-xl" type="text" name="type" value={newBlog.type}
                       onChange={handleChange}/>
            </label>
        </div>
    )
}

export default CreateDoctorForm;
