import axios from "axios";

import Cookies from "js-cookie";
import {IItem} from "../interfaces/item.interface";

const baseUrl = "https://kovalchuk-clinic-api.onrender.com/";
const token = Cookies.get("token");

axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            Cookies.remove("token");
            window.location.href = "/";
        }
        return Promise.reject(error);
    });

export const getItems = async () => {
    const response = await axios.get(baseUrl + "items");
    return response.data;
};

export const getItemById = async (id: number) => {
    const response = await axios.get(baseUrl + `items/${id}`);
    return response.data;
}

export const createItem = async (item: IItem)=> {
    const response = await axios.post(baseUrl + "items", item);
    return response.data;
}

export const updateItem = async (id: number, item: IItem) => {
    const response = await axios.patch(baseUrl + `items/${id}`, item);
    return response.data;
}

export const deleteItem= async (id: number) => {
    const response = await axios.delete(baseUrl + `items/${id}`);
    return response.data;
}
