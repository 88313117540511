
const ImageToBase64 = ({setNewBlog, newBlog}: { setNewBlog: (any: any) => void, newBlog: any }) => {

    const onChange = (e: any) => {
        const files = e.target.files;
        const file = files[0];
        getBase64(file);
    };

    const getBase64 = (file: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setNewBlog({...newBlog, "image": reader.result})
        };
    };

    return (
        <div>
            <form>
                <input type="file" onChange={onChange}/>
            </form>
        </div>
    )
}

export default ImageToBase64
