import React from "react";

const CreateServiceForm = ({setNewBlog, newBlog} : {setNewBlog: any, newBlog: any}) => {

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setNewBlog({
            ...newBlog,
            [name]: value
        })
    }

    return (
        <div className="flex flex-col gap-y-3">
            <label className="flex flex-col gap-y-2">
                Title:
                <input className="p-2 border border-black rounded-xl" type="text" name="title" value={newBlog.name} onChange={handleChange}/>
            </label>
        </div>
    )
}

export default CreateServiceForm;
