import { Layout, Menu } from "antd";
import { ReactElement, createElement } from "react";
import Logo from "../../assets/svg/Logo";

const { Content, Footer, Sider } = Layout;

type Props = {
    children: ReactElement;
    setView: (view: string) => void;
};

export const AppLayout: React.FC<Props> = ({ children, setView }) => {

    return (
        <Layout className="h-screen">
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
            >
                <center>
                    <Logo />
                </center>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={["blogs"]}
                    items={[
                        { icon: "a", label: "Блог", view: "blogs"},
                        {icon: "b", label: "Послуги", view: "items"},
                        {icon: "c", label: "Напрями", view: "services"},
                        {icon: "d", label: "Лікарі", view: "doctors"},
                    ].map((item, index) => ({
                        key: item.view,
                        icon: createElement(item.icon),
                        label: item.label,
                        onClick: () => {
                            setView(item.view);
                        },
                    }))}
                />
            </Sider>
            <Layout>
                <Content style={{ margin: "16px 32px" }}>
                    {children}
                </Content>
                <Footer style={{ textAlign: "center" }}>Kovalchuk Clinic Admin</Footer>
            </Layout>
        </Layout>
    );
};
