import axios from "axios";

import Cookies from "js-cookie";
import {IDoctor} from "../interfaces/doctors.interface";

const baseUrl = "https://kovalchuk-clinic-api.onrender.com/";
const token = Cookies.get("token");

axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            Cookies.remove("token");
            window.location.href = "/";
        }
        return Promise.reject(error);
    });

export const getDoctors = async () => {
    const response = await axios.get(baseUrl + "doctors");
    return response.data;
};

export const getDoctorById = async (id: number) => {
    const response = await axios.get(baseUrl + `doctors/${id}`);
    return response.data;
}

export const createDoctor = async (doctor: IDoctor)=> {
    const response = await axios.post(baseUrl + "doctors", doctor);
    return response.data;
}

export const updateDoctor = async (id: number, doctor: IDoctor) => {
    const response = await axios.patch(baseUrl + `doctors/${id}`, doctor);
    return response.data;
}

export const deleteDoctor= async (id: number) => {
    const response = await axios.delete(baseUrl + `doctors/${id}`);
    return response.data;
}
